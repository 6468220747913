import React from 'react'

const Branch = ({ location, descriptions, mapsLink, wazeLink, number }) => {
  return (
    <div className='branch-item'>
        <div className='branch-location'>
            {location}
        </div>
        <div className='branch-description'>
            {descriptions.map((desc, index) => (
                <p key={index}>{desc}</p>
            ))}
        </div>
        <div className='branch-links'>
            {mapsLink && (
                <a href={mapsLink} target='_blank' rel='noopener noreferrer'>
                    Maps 🗺️
                </a>
            )}
            {wazeLink && (
                <a href={wazeLink} target='_blank' rel='noopener noreferrer'>
                    Waze 🚗
                </a>
            )}
        </div>
        {number && <div className='branch-number'>{number}</div>}
    </div>
  )
}

export default Branch