import React from "react";
import Header from "./Header/Header";
import ProductsSlider from "./ProductsSlider/ProductsSlider";
import Branches from "./Branches/Branches";
import Contact from "./contact/Contact";
import PromotionButton from "./Promotion/PromotionButton";

const StudioVisual = () => {
  return (
    <>
      <Header className="section-spacing" />
      <ProductsSlider className="section-spacing" />
      <PromotionButton className="section-spacing" />
      <Branches className="section-spacing" />
      <Contact className="section-spacing" />
    </>
  );
};

export default StudioVisual;
