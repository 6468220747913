import React, { useState } from 'react';
import './PromotionButton.css';
import Container from '../Container';

const PromotionButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container>
      <div className="promotion-section">
        <p className="promotion-text">
          Para conocer más sobre nuestras promociones y ofertas especiales, por favor haga clic en el siguiente botón
        </p>
        <button 
          className="promotion-button"
          onClick={() => setIsModalOpen(true)}
        >
          Ver promociones
        </button>

        {isModalOpen && (
          <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <button 
                className="modal-close"
                onClick={() => setIsModalOpen(false)}
              >
                ×
              </button>
              <div className="modal-scroll">
                <h2>REGLAS Y CONDICIONES DE PROMOCIÓN</h2>
                <div className="promotion-rules">
                  <p>Las promociones "TODO INCLUIDO" se regirá por las siguientes reglas y condiciones. Por el solo hecho de participar en la promoción, los participantes aceptan todas las reglas de este reglamento en forma incondicional, incluyendo aquellas cláusulas que limitan la responsabilidad de CLÍNICA STUDIO VISUAL, Asimismo, aceptan las restricciones del programa dando por hecho que conocen las restricciones establecidas.</p>
                  
                  <h3>CONDICIONES GENERALES DE LA PROMOCIÓN:</h3>
                  <ol>
                    <li>Esta promoción es diseñada y promovida por CLÍNICA STUDIO VISUAL S.A</li>
                    <li>Esta promoción se denomina "TODO INCLUIDO".</li>
                  </ol>

                  <h3>REGLAS DE LA PROMOCIÓN:</h3>
                  <ol>
                    <li>La promoción está diseñada para graduaciones de 0.25 a 2.00 D P en cualquier problema visual: miopía, astigmatismo, presbicia e hipermetropía.</li>
                    <li>Entiéndase "problema visual": Aumento dióptrico especial y personalizado para que el paciente obtenga su mejor visión de lejos o de cerca.</li>
                  </ol>

                  <ul>
                    <li>Lentes: El material utilizado para las promociones es plástico.</li>
                    <li>Aro: Contamos con una línea de 150 opciones a escoger entre metal y pasta, con garantía de 3 meses por cualquier defecto de fábrica.</li>
                    <li>El examen de la vista está incluido en la promoción sin embargo de necesitar solo la consulta se deberá cancelar el costo del examen el cual es un monto estipulado por el Colegio de Optometristas.</li>
                    <li>Las promociones son válidas solo los días que se especifiquen en la publicidad.</li>
                    <li>Clínica Studio Visual no puede realizar cambio de promociones después de ser facturada, quiere decir que el día que el cliente nos visite y realice la compra de una promoción en especial, deberá cancelar el precio de la promoción de ese día. No es permitido utilizar promociones de otros días.</li>
                    <li>Clínica Studio Visual no puede realizar devoluciones de dinero a un cliente que compró una promoción en una fecha especifica, y en otra fecha querer cambiar su promoción anterior por la actual promoción a un precio menor.</li>
                    <li>Clínica Studio Visual no puede realizar cambios de aros, cambios de diseño de lente luego de realizar la factura y ser enviados al laboratorio.</li>
                    <li>Clínica Studio Visual cuenta con 8 días hábiles para la entrega.</li>
                  </ul>

                  <h3>DESCRIPCIÓN DE LA PROMOCIÓN:</h3>
                  <p>En cuanto a los Packs TODO INCLUIDO la promoción funciona de la siguiente manera:</p>
                  <ol>
                    <li>Aplica para lentes con aumento en una sola visión: lejos o cerca. (NO APLICA EN BIFOCAL NI PROGRESIVO).</li>
                    <li>El precio aplica para la compra del paquete completo (ARO + LENTE).</li>
                    <li>De comprar solo el lente y que el aro sea del cliente se cobrará ÚNICAMENTE EL PRECIO DEL LENTE.</li>
                    <li>El aro está incluido en el paquete cuando los lentes promocionados sean montados o utilizados para el aro que se elige dentro de la opción seleccionada por Clínica Studio Visual.</li>
                    <li>De comprar solo el aro se cobrará el precio del aro únicamente, no tendrá descuentos adicionales.</li>
                    <li>Se deberá abonar el 50% del valor de la factura para ser enviados a confeccionar, las facturas que no sean canceladas no se envian al laboratorio.</li>
                    <li>La promoción no aplica para SISTEMA DE APARTADO A 50 DÍAS.</li>
                    <li>De no contar con el 50% del monto final promocionado, la promoción no puede ser enviada al laboratorio.</li>
                    <li>El examen de la vista está incluido en los paquetes especiales, de no ser facturado el paquete promocional deberá facturarse el examen de la vista, según lo que estipula el Colegio de Optometristas. El precio minimo del examen es de 5.000 colones exactos.</li>
                  </ol>

                  <p>En cuanto al 2x1 o "Paga el de mayor valor, segundo es GRATIS", la promoción funciona de la siguiente manera:</p>
                  <ol>
                    <li>Aplica para TODO PÚBLICO, cualquier aumento y cualquier lente: lejos o cerca, bifocal o progresivo.</li>
                    <li>La promoción la definirá el producto de mayor valor quiere decir, que la cotización final será la determinante para establecer el total de la compra, ya que se pagará el de mayor valor y el segundo será completamente GRATIS.</li>
                    <li>Aplica para la compra del paquete completo (ARO + LENTE), y no aplica para lentes de contacto, o aros de sol.</li>
                    <li>De comprar solo el lente y que el aro sea del cliente se cobrará ÚNICAMENTE EL PRECIO DEL LENTE.</li>
                    <li>El aro se seleccionará entre una amplia gama de opciones con más de 30 opciones entre hombre y mujer.</li>
                    <li>De comprar solo el aro se cobrará el precio del aro únicamente, no tendrá descuentos adicionales.</li>
                    <li>Se deberá abonar el 50% del valor de la factura para ser enviados a confeccionar, las facturas que no sean canceladas no se envían al laboratorio.</li>
                    <li>La promoción no aplica para SISTEMA DE APARTADO A 50 DÍAS.</li>
                    <li>De no contar con el 50% del monto final promocionado, la promoción no puede ser enviada al laboratorio.</li>
                    <li>El examen de la vista está incluido en los paquetes especiales, de no ser facturado el paquete promocional deberá facturarse el examen de la vista, según lo que estipula el Colegio de Optometristas. El precio mínimo del examen es de 5.000 colones exactos.</li>
                  </ol>

                  <h3>RESTRICCIONES:</h3>
                  <ol>
                    <li>La graduación o aumento del examen no debe estar fuera del siguiente rango 0.25 a 2.00 D.</li>
                    <li>El diseño y material será acorde a la marca y producto ofrecido dentro de la promoción.</li>
                    <li>No aplican financiamiento con pagos con tasa cero, Credix o BN cero para esta promoción.</li>
                    <li>No aplican descuentos adicionales</li>
                    <li>La fecha de la promoción está sujeta a la fecha establecida por CLÍNICA STUDIO VISUAL según sus publicaciones.</li>
                  </ol>

                  <h3>FECHA DE VIGENCIA DE LA PROMOCIÓN:</h3>
                  <ol>
                    <li>La fecha de la promoción está sujeta a la fecha establecida por CLÍNICA STUDIO VISUAL según sus publicaciones en Facebook.</li>
                  </ol>

                  <p>Para mayor información o consultas sobre esta promoción puede comunicarse a la sucursal de su preferencia o al WhatsApp 8472-7777 exclusivamente por mensaje de texto.</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default PromotionButton;