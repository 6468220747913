import React from "react";

import "./Branches.css";

import Container from "../Container";

import Branch from "./Branch";



const Branches = () => {

  return (

    <Container>

      <section className="branches">

        <div className="branches-title">

          <div className="b-title">Sucursales</div>

          <div className="b-description">

            SUCURSALES A SU DISPOSICIÓN EN TODO EL PAÍS

          </div>

        </div>

        <div className="branches-items">

          <Branch

            location="CENTRAL"

            descriptions={[

              "75 metros al norte de la terminal de tracopa",

              "De la esquina del Fast Print Central 150 metros al sur",

              "Del parqueo del Hospital Clínica Biblica 250 metros al este y 25 metros al sur"

            ]}

            mapsLink="https://maps.app.goo.gl/8htcVSDfXfaxxJVa9"

            wazeLink="https://ul.waze.com/ul?place=ChIJW9qL7X3joI8RU6sd8qXDug4&ll=9.92665110%2C-84.07748340&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="CARTAGO"

            descriptions={[

              "Cartago, frente a la Estación de Bomberos 🚒",

              "De la Catedral Nuestra Señora del Carmen 125 metros al oeste y 50 metros al norte"

            ]}

            mapsLink="https://maps.app.goo.gl/QxG6w35DVovikBFr9"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=180944995.1809318875.16910260&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="ALAJUELA"

            descriptions={[

              "Alajuela, 75 mts sur de los semáforos de la Corte",

              "Del Juzgado de tránsito de Alajuela 50 metros suroeste y 50 metros al sur"

            ]}

            mapsLink="https://maps.app.goo.gl/v1q4DhYCeS4hbzJe7"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=180748388.1807352810.15258105&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="GUADALUPE"

            descriptions={[

              "Guadalupe, 75 metros oeste del Parque de Guadalupe, frente a la Chicharronera Goicoechea",

              "De la Parroquia de Nuestra Señora de Guadalupe 75 metros al suroeste y 150 metros al oeste"

            ]}

            mapsLink="https://maps.app.goo.gl/r8gcMThBcjQm311G7"

            wazeLink="https://ul.waze.com/ul?place=ChIJK3mUt8TloI8RU0lPisbYIZY&ll=9.94557400%2C-84.05365580&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="SAN RAMON"

            descriptions={[

              "San Ramón, Del BAC San José 25 metros al este, diagonal al Banco Popular, local esquinero",

              "De la estación de bomberos 100 metros al oeste, esquinero se encuentra Clínica Studio Visual"

            ]}

            mapsLink="https://maps.app.goo.gl/8wa4ySswY79qMUWg9"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=180748388.1807352810.15258105&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="EL ROBLE"

            descriptions={[

              "El Roble de Puntarenas, Frente al Banco de Costa Rica"

            ]}

            mapsLink="https://maps.app.goo.gl/rzcFaFjigTdmzKaeA"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=180420708.1804010470.24402034&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="CAÑAS"

            descriptions={[

              "Cañas, Contiguo al Banco Nacional frente al costado noroeste de la Iglesia Católica"

            ]}

            mapsLink="https://maps.app.goo.gl/YGJwaYcvYuo9p1MZ8"

            wazeLink="https://ul.waze.com/ul?place=ChIJUQLdDRb7n48RJt_Ih61KNrk&ll=10.42695560%2C-85.09126830&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="TRES RIOS"

            descriptions={[

              "Tres Ríos, 50 metros este del Más x Menos, Edificio Sabino Guzmán frente a Plaza Estación"

            ]}

            mapsLink="https://maps.app.goo.gl/snxLcuiVD6LjVmMaA"

            wazeLink="https://ul.waze.com/ul?place=ChIJ6cmToMnhoI8RVN9FnFqA7Rc&ll=9.90863520%2C-83.98812950&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="SAN RAFAEL"

            descriptions={[

              "San Rafael de Alajuela, 300mts oeste del Megasúper"

            ]}

            mapsLink="https://maps.app.goo.gl/YXPtWmPoYQKAMVEv7"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=179961962.1799357479.24401414&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="LIBERIA"

            descriptions={[

              "Liberia, 75 mts oeste de Correos de Costa Rica frente a Ferretería San Carlos"

            ]}

            mapsLink="https://maps.app.goo.gl/8Ea1KMaSgdMzcMbe9"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=179961962.1799357479.24401414&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="HEREDIA"

            descriptions={[

              "Heredia, del Banco Popular del Liceo de Heredia 75 metros al este sobre calle principal"

            ]}

            mapsLink="https://maps.app.goo.gl/A4py8Zf1Aew1T22i9"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=180813924.1808073704.15917663&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="GUAPILES"

            descriptions={[

              "Guápiles, Plaza Comercial Guápiles, contiguo a Subway"

            ]}

            mapsLink="https://maps.app.goo.gl/4WNDe1Z3Dx2zVi978"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=181010534.1810170878.19879810&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="NICOYA"

            descriptions={[

              "Nicoya, 300 metros norte del Hospital La Anexión, diagonal a Ekono, frente a Colono Agropecuario"

            ]}

            mapsLink="https://maps.app.goo.gl/QH7YaXtykoSpyifc9"

            wazeLink="https://ul.waze.com/ul?place=ChIJQc30MaK3n48Rvh-H5rDyOSY&ll=10.15290510%2C-85.45113950&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="SAN CARLOS"

            descriptions={[

              "San Carlos, frente a las oficinas de la CCSS"

            ]}

            mapsLink="https://maps.app.goo.gl/ywGTNXK9CswKmeSv6"

            wazeLink="https://ul.waze.com/ul?place=ChIJMSVny8hloI8R1cBeFF0FJvE&ll=10.32881850%2C-84.43155230&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

          <Branch

            location="SAN JOSÉ AV8"

            descriptions={[

              "San José, del Diario Extra 200 metros al sur y 50 metros este sobre Av8"

            ]}

            mapsLink="https://maps.app.goo.gl/CS7ctqsouabyYnkN8"

            wazeLink="https://ul.waze.com/ul?place=ChIJo6qagl3joI8Rdgv6XSvqYeE"

          />

          <Branch

            location="PÉREZ ZELEDÓN"

            descriptions={[

              "Pérez Zeledón, De la Casa de la Juventud 50 metros al oeste en la esquina Clínica San Lucas"

            ]}

            mapsLink="https://maps.app.goo.gl/ugq6WM3yviR2mhWM7"

            wazeLink="https://ul.waze.com/ul?preview_venue_id=181076062.1810760617.17588489&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"

          />

        </div>

        <div className="schedule">

          Lunes a Sábado: 9:00 am a 6:00 pm Domingo: CERRADO

        </div>

      </section>

    </Container>

  );

};



export default Branches;


